import { availableLocales } from 'config/availableLocales';
import { defineConfig } from 'features/featureFlag/defineConfig';

const languageOptions = [
  ...availableLocales.map(({ code, displayName }) => ({
    label: displayName,
    value: code,
  })),
  /**
   * HINT: For easy testing - setting lng to 'cimode' will set t function to always return the key.
   */
  {
    label: 'Keys',
    value: 'cimode',
  },
];

const debugFeatureFlags = defineConfig({
  debugDivider: {
    type: 'divider',
    label: 'Debug Tools',
  },
  'show-feature-flag-icon': {
    label: 'Show feature flag icon',
    type: 'toggle',
  },
  'quick-i18n': {
    label: 'Quick switch language',
    type: 'singleSelect',
    options: languageOptions,
  },
  'enabled-server-region': {
    label: 'Update server region',
    type: 'singleSelect',
    options: [
      {
        value: 'global',
        label: 'Global',
      },
      {
        value: 'jp',
        label: 'Japan',
      },
    ],
  },
  'react-query-devtool': {
    label: 'Enable react-query devtool',
    type: 'singleSelect',
    options: [
      {
        value: 'bottom-right',
        label: 'Bottom right',
      },
      {
        value: 'top-left',
        label: 'Top left',
      },
      {
        value: 'top-right',
        label: 'Top right',
      },
      {
        value: 'bottom-left',
        label: 'Bottom left',
      },
    ],
  },
  'show-member-id-in-contact-profile': {
    label: 'Show member ID in Contact profile',
    type: 'toggle',
  },
  'reveal-segment-id': {
    label: 'Show segment ID in Broadcast list',
    type: 'toggle',
  },
});

const releasedFeatureFlags = defineConfig({
  releasedFeatures: {
    type: 'divider',
    label: 'Released Features',
  },
  'auto-segment': { type: 'toggle' },
  'integration-pages': { type: 'toggle' },
  'show-task-key': { label: 'Show task key in TaskProgressModal', type: 'toggle' },
  'show-pushed-tables-card': {
    label:
      'Show auto-reply and tag tables in Insights/Engagement. Show total used point in Insights/Notification Message',
    type: 'toggle',
  },
  'show-journey-v2-scheduled-status': {
    label: 'Show Customer Journey V2 Scheduled Status',
    type: 'toggle',
  },
  'activate-api-client': {
    label: 'Use new typesafe API client for data fetching',
    type: 'toggle',
  },
  'enable-table-view-v2': {
    label: 'Show table view V2 components',
    type: 'toggle',
  },
  vitally: { label: 'Show Vitally NPS', type: 'toggle' },
  'auto-disable-auto-reply-new-feature-tag': {
    label: 'Auto disable Auto-reply new feature tag',
    type: 'toggle',
    autoEnableAt: new Date('2025-02-27T15:00:00+08:00'),
  },
  'enable-segment-GA-tooltip-improvement': {
    label: 'Enable segment GA tooltip improvement',
    type: 'toggle',
  },
});

export const featureFlagConfigs = defineConfig({
  ...debugFeatureFlags,
  ...releasedFeatureFlags,
});
