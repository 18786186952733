import { z } from 'zod';

import { isCdpTagUniqueId } from 'shared/utils/cdp';

/**
 * Note: changes will soon (2025Q1) be introduced to allow for third party tag data sources
 * These may be arbitrary strings as opposed to a set of predefined values
 */
export const CdpDataSourceEnum = {
  Maac: 'maac',
  Caac: 'caac',
} as const;

export const CdpDataSourceSchema = z.nativeEnum(CdpDataSourceEnum);

export type CdpDataSource = z.output<typeof CdpDataSourceSchema>;

export type CdpTagUniqueId = `${CdpDataSource}-${number}`;

export const CdpTagUniqueIdSchema = z.custom<CdpTagUniqueId>(isCdpTagUniqueId);
