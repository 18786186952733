import { toNumber } from '@chatbotgang/etude/pitch-shifter/number';

import {
  defaultImagemapCarouselActionBlock,
  IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH,
} from 'shared/components/Editor/LineMessageEditor/components/ImagemapCarouselModule/constants';

import type {
  ImagemapCarouselModule,
  ImagemapContentBox,
  ImagemapContents,
} from 'shared/components/Editor/LineMessageEditor/types';

import { typedEntries } from 'shared/utils/typedObjectMethods';

/**
 * Parse aspect ratio to width and height. The return width and height are the original width and height of the image
 * @example
 * ```ts
 * parseWidthAndHeight('3:1'); // { width: 3, height: 1, aspectRatio: 3 }
 * ```
 */
export const parseWidthAndHeight = (
  aspectRatio: string,
): {
  /** original image width */
  width: number;
  /** original image height */
  height: number;
  aspectRatioValue: number;
} => {
  const [width, height] = aspectRatio.split(':');
  const parsedWidth = toNumber(width, { defaultValue: 0 });
  const parsedHeight = toNumber(height, { defaultValue: 1 });
  return {
    width: parsedWidth,
    height: parsedHeight,
    aspectRatioValue: parsedWidth / parsedHeight,
  };
};

export const findSmallestAspectRatio = (message: ImagemapCarouselModule) =>
  Math.min(
    ...message.data.contents.contents.map((imagemap) => {
      const [imagemapImage] = imagemap.body.contents;
      const { aspectRatioValue } = parseWidthAndHeight(imagemapImage.aspectRatio ?? '1:1');
      return aspectRatioValue;
    }),
  );

/**
 * This function transforms the offset values from a flex box to the actual position and size of the action block
 *
 * @param layoutSize - Image width and height on layout
 * @param offsets - Offset values from a flex box
 */
export const getActionBlockStylesFromOffsets = (
  layoutSize: { width: number; height: number },
  offsets: {
    width: string;
    height: string;
    offsetTop: string;
    offsetStart: string;
  },
) => {
  const area = Object.fromEntries(
    typedEntries(offsets).map(([key, value]) => [
      key,
      (toNumber(value.replace('%', ''), { defaultValue: 0 }) * 100) / 10000,
    ]),
  );

  return {
    top: area.offsetTop * layoutSize.height,
    left: area.offsetStart * layoutSize.width,
    width: area.width * layoutSize.width,
    height: area.height * layoutSize.height,
  };
};

export const parseImagemapCarouselBlocks = (content: ImagemapContents) => {
  const [imagemapContentImage, ...imagemapContentBoxes] = content.body.contents;
  const { aspectRatioValue } = parseWidthAndHeight(imagemapContentImage.aspectRatio ?? '1:1');

  return imagemapContentBoxes.map(
    ({ width = '', height = '', offsetStart = '', offsetTop = '' }, index) => {
      const styles = getActionBlockStylesFromOffsets(
        {
          width: IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH,
          height: IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH / aspectRatioValue,
        },
        { width, height, offsetStart, offsetTop },
      );
      return {
        ...styles,
        id: index + 1,
      };
    },
  );
};

export const createImagemapCarouselActionBlock = (
  originalSize: { width: number; height: number },
  areaSize: {
    x: number;
    y: number;
    width: number;
    height: number;
  },
): ImagemapContentBox => {
  const { width, height } = originalSize;
  const { width: areaWidth, height: areaHeight, x: areaX, y: areaY } = areaSize;

  return {
    ...defaultImagemapCarouselActionBlock,
    width: `${((areaWidth / width) * 100).toFixed(2)}%`,
    height: `${((areaHeight / height) * 100).toFixed(2)}%`,
    offsetTop: `${((areaY / height) * 100).toFixed(2)}%`,
    offsetStart: `${((areaX / width) * 100).toFixed(2)}%`,
  };
};

export const createImagemapCarouselPredefinedBlocks = (
  originalSize: { width: number; height: number },
  count: number,
): Array<ImagemapContentBox> => {
  const imageAspectRatioValue = originalSize.width / originalSize.height;

  const fullArea = {
    x: 0,
    y: 0,
    width: originalSize.width,
    height: originalSize.height,
  };

  const actionBlocks: Array<ImagemapContentBox> = [];

  for (let i = 0; i < count; i++) {
    if (count === 1) {
      actionBlocks.push(createImagemapCarouselActionBlock(originalSize, fullArea));
    }

    if (count === 4) {
      const width = fullArea.width / 2;
      actionBlocks.push(
        createImagemapCarouselActionBlock(originalSize, {
          x: (i + 1) % 2 === 0 ? width : 0,
          y: i + 1 < 3 ? 0 : width / imageAspectRatioValue,
          width,
          height: width / imageAspectRatioValue,
        }),
      );
    }

    if (count === 6) {
      const width = fullArea.width / 3;
      const height = fullArea.height / 2;
      actionBlocks.push(
        createImagemapCarouselActionBlock(originalSize, {
          x: (i + 1) % 3 === 0 ? width * 2 : (((i + 1) % 3) - 1) * width,
          y: i + 1 < 4 ? 0 : height,
          width,
          height,
        }),
      );
    }

    if (count === 8) {
      const width = fullArea.width / 2;
      const height = fullArea.height / 4;
      actionBlocks.push(
        createImagemapCarouselActionBlock(originalSize, {
          x: (i + 1) % 2 === 0 ? width : 0,
          y: (Math.floor((i + 2) / 2) - 1) * height,
          width,
          height,
        }),
      );
    }
  }

  return actionBlocks;
};
