import styled from '@emotion/styled';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  EDITOR_CUSTOM_TAG_REGEX,
  EDITOR_CUSTOM_TEXT_TAG_REGEX,
  EDITOR_NAME_TAG_REGEX,
  EDITOR_TAG_REGEX,
} from 'shared/components/Editor/constants';
import { PARAM_PLACEHOLDER } from 'shared/components/Editor/LineMessageEditor/constants';

import type { CardContent, Parameter } from 'shared/components/Editor/LineMessageEditor/types';

import { getColorByTagType } from 'shared/components/Editor/LineMessageEditor/utils/getColorByTagType';
import {
  isCustomParameter,
  isCustomParameterWithLink,
  isCustomParameterWithSharelink,
  isStandardParameter,
} from 'shared/components/Editor/LineMessageEditor/utils/helper';
import { transformKey } from 'shared/components/Editor/utils/helper';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { Typography } from 'shared/components/Typography';
import { testIds } from 'shared/lib/cypress/testIds';
import { theme } from 'theme';

const Img = styled.img`
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const CardPreviewContainer = styled.div`
  width: 223px;
  border-radius: 16px;
  background-color: ${theme.colors.white001};
`;

const FooterContainer = styled.div`
  padding: 8px;
  text-align: center;
`;

export const CardModulePreview = ({
  parameters,
  cardContent,
}: {
  parameters: Array<Parameter>;
  cardContent: CardContent;
}) => {
  const { t } = useTranslation();
  const imageUrl = cardContent.hero?.url ?? '';
  const [card, description] = cardContent.body.contents;
  const heroParameterKey = cardContent.hero?.key ?? '';

  const heroParameter = useMemo(
    () =>
      heroParameterKey
        ? (parameters.find(
            (parameter) =>
              isCustomParameter(parameter) && parameter.mappingKey === heroParameterKey,
          )?.key ?? '')
        : '',
    [parameters, heroParameterKey],
  );

  const transformText = useCallback(
    (text: string) => {
      return text.split(EDITOR_TAG_REGEX).map((line, index) => {
        if (line.match(EDITOR_NAME_TAG_REGEX)) {
          return (
            <span
              key={`name-${index}`}
              style={{
                color: getColorByTagType('name'),
              }}
            >
              {t('member.name')}
            </span>
          );
        }
        if (line.match(EDITOR_CUSTOM_TAG_REGEX) || line.match(EDITOR_CUSTOM_TEXT_TAG_REGEX)) {
          const parameter = parameters.find(
            (parameter) =>
              isCustomParameter(parameter) && parameter.mappingKey === transformKey(line),
          );
          return (
            <span
              key={`custom-${index}`}
              style={{
                color: getColorByTagType('custom'),
              }}
            >
              {t('common.textsWithColon', {
                textBeforeColon: t('glossary.apiParameter'),
                textAfterColon: parameter?.key,
              })}
            </span>
          );
        }
        return line;
      });
    },
    [parameters, t],
  );

  const heroActionHint = useMemo(() => {
    const heroActionType = cardContent.hero?.action?.type;
    switch (heroActionType) {
      case 'message':
        return `${t('message.send')}: ${cardContent.hero?.action?.text}`;
      case 'uri':
        const uriParameter = parameters.find((parameter) => {
          return (
            isStandardParameter(parameter) &&
            heroActionType === 'uri' &&
            parameter.key === cardContent.hero?.action?.uri
          );
        });
        if (uriParameter && uriParameter.data && isCustomParameterWithLink(uriParameter.data)) {
          return `${t('common.openUrl')}: ${uriParameter.data.url}`;
        }
        return '';
      case 'customUri':
        const customUriParameter = parameters.find((parameter) => {
          return (
            isCustomParameter(parameter) &&
            heroActionType === 'customUri' &&
            parameter.mappingKey === cardContent.hero?.action?.key
          );
        });
        if (customUriParameter) {
          return `${t('message.apiUrl')}: ${customUriParameter.key}`;
        }
        return '';
      default:
        return '';
    }
  }, [cardContent, parameters, t]);

  return (
    <CardPreviewContainer>
      <Tooltip
        trigger="hover"
        title={
          heroParameter ? (
            <TooltipDescription>
              {t('message.paramImageEditor.imagePreview.tooltip', {
                paramName: heroParameter,
              })}
            </TooltipDescription>
          ) : heroActionHint ? (
            <TooltipDescription>{heroActionHint}</TooltipDescription>
          ) : (
            ''
          )
        }
      >
        {imageUrl === PARAM_PLACEHOLDER.image ? (
          <Img src={imageUrl} />
        ) : (
          <Img src={imageUrl} data-test={testIds.cardPreviewLocalUploadImage} />
        )}
      </Tooltip>
      <div
        style={{
          backgroundColor: theme.colors.white001,
          borderRadius: imageUrl ? '0 0 16px 16px' : '16px',
        }}
      >
        <div
          style={{
            padding: '12px 24px',
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          <Tooltip
            trigger="hover"
            title={heroActionHint ? <TooltipDescription>{heroActionHint}</TooltipDescription> : ''}
          >
            <Typography
              variant="body"
              fontWeight={500}
              style={{
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
              }}
            >
              {transformText(card.text)}
            </Typography>
            <Typography
              variant="body"
              fontWeight={500}
              color={theme.colors.neutral007}
              style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
            >
              {transformText(description.text)}
            </Typography>
          </Tooltip>
        </div>
        <FooterContainer>
          {cardContent.footer.contents.map((content, index) => {
            let title = '';
            let url = '';

            if (content.action.type === 'message') {
              title = `${t('message.send')}: ${content.action.text}`;
            }
            if (content.action.type === 'uri') {
              const uriParameter = parameters.find((parameter) => {
                return isStandardParameter(parameter) && parameter.key === content.key;
              });
              if (uriParameter && uriParameter.data) {
                title = isCustomParameterWithLink(uriParameter.data)
                  ? `${t('common.openUrl')}: ${uriParameter.data.url}`
                  : isCustomParameterWithSharelink(uriParameter.data)
                    ? `${t('message.shareButton.name')}: ${uriParameter.data.name}`
                    : '';
                url = isCustomParameterWithLink(uriParameter.data) ? uriParameter.data.url : '';
              }
              const customUriParameter = parameters.find((parameter) => {
                return isCustomParameter(parameter) && parameter.mappingKey === content.key;
              });
              if (customUriParameter) {
                title = `${t('message.apiUrl')}: ${customUriParameter.key}`;
              }
            }
            return (
              <Tooltip
                key={`action-${index}`}
                trigger="hover"
                title={<TooltipDescription>{title}</TooltipDescription>}
              >
                <Typography
                  variant="body"
                  color={theme.colors.blue006}
                  style={{ cursor: 'pointer', marginBottom: 12 }}
                  onClick={() => {
                    if (content.action.type === 'uri' && url) {
                      window.open(url);
                    }
                  }}
                >
                  {content.action.label}
                </Typography>
              </Tooltip>
            );
          })}
        </FooterContainer>
      </div>
    </CardPreviewContainer>
  );
};
