import { IMAGEMAP_UI_IMAGE_WIDTH } from 'shared/components/Editor/LineMessageEditor/components/ImagemapModule/constants';

import type { ComponentProps } from 'react';

import { flexCenter, hideScrollBar } from 'components/style-helper';
import { css, shouldNotForwardProps, styled } from 'shared/utils/styled';
import { theme } from 'theme';

export const DeleteIconWrapper = styled.div`
  i {
    transition: color 0.2s ease-in-out;
  }
  &:hover {
    i {
      color: ${({ theme }) => theme.colors.red006};
    }
  }
`;

export const RowToolBoxWrapper = styled.span`
  height: 53px;
  width: 16px;
  position: absolute;
  right: -16px;
  top: 0;
  opacity: 0;
  padding-top: 2px;
  transition: 0.25s opacity ease-in-out;
  .editor-delete-icon {
    cursor: pointer;
    margin-left: 4px;
    path {
      transition: 0.25s fill ease-in-out;
    }
    &:hover {
      path {
        fill: ${theme.colors.red006};
      }
    }
  }
  .editor-verticaldrag-icon {
    cursor: pointer;
    path {
      transition: 0.25s fill ease-in-out;
    }
    &:hover {
      path {
        fill: ${theme.colors.blue006};
      }
    }
  }
`;

export const ImageCoverWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 56, 72, 0.8);
  z-index: 200;
  opacity: 0;
  transition: 0.45s opacity ease-in-out;
  border-radius: 8px;
  &:hover {
    opacity: 1;
    ${RowToolBoxWrapper} {
      opacity: 1;
    }
  }
`;

export const ImageHoverBoxIconWrapper = styled.label`
  color: ${theme.colors.neutral003};
  text-align: center;
  cursor: pointer;
  display: block;
  margin: 10px;
  input[type='file'] {
    display: none;
  }
  &:hover {
    color: ${theme.colors.blue004};
  }
`;

const IMAGE_WRAPPER_PADDING = 8;

interface ImageWrapperProps extends ComponentProps<'div'> {
  flatBorderBottom?: boolean;
}

export const ImageWrapper = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['flatBorderBottom']),
})<ImageWrapperProps>`
  border: 2px solid ${theme.colors.neutral004};
  width: 353px;
  height: 353px;
  margin: auto;
  margin-bottom: 32px;
  position: relative;
  border-radius: ${({ flatBorderBottom = false }) => (flatBorderBottom ? '10px 10px 0 0' : '10px')};
  box-sizing: border-box;
  background-color: white;
  user-select: none;
  cursor: pointer;
  display: flex;
  padding: ${IMAGE_WRAPPER_PADDING}px;
  justify-content: center;
  align-items: center;
  transition:
    border 0.25s ease-in-out,
    height 0.55s ease-in-out;
  .image-editor-icon {
    color: ${theme.colors.neutral006};
    font-size: 20px;
    position: absolute;
    top: 55px;
    left: calc(50% - 10px);
  }
  &:hover {
    border-color: ${theme.colors.blue006};
    ${RowToolBoxWrapper} {
      opacity: 1;
    }
  }
  &.is-error {
    border-color: ${theme.colors.red006} !important;
  }
  &.is-drag-mode {
    > div,
    label {
      pointer-events: none;
    }
  }
`;

interface UploadImageLabelProps {
  bgUrl: string;
  type: 'image' | 'imagemap';
  flatBorderBottom?: boolean;
}

export const UploadImageLabel = styled.label.withConfig({
  shouldForwardProp: shouldNotForwardProps(['bgUrl', 'type', 'flatBorderBottom']),
})<UploadImageLabelProps>`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 100;
  background-image: ${({ bgUrl }) => (bgUrl === '' ? 'none' : `url(${bgUrl})`)};
  background-size: ${({ type }) => (type === 'image' ? 'cover' : 'contain')};
  background-repeat: no-repeat;
  border-radius: ${({ flatBorderBottom = false }) => (flatBorderBottom ? '8px 8px 0 0' : '8px')};
  background-position: center center;
  cursor: pointer;
  input[type='file'] {
    display: none;
  }
`;

export const ImagemapScaledImage = styled.img`
  width: ${IMAGEMAP_UI_IMAGE_WIDTH}px;
  height: auto;
  border-radius: 8px;
`;

export const ImageMapCarouselScaledImage = styled.img`
  width: calc(100% + ${IMAGE_WRAPPER_PADDING * 2}px);
  height: auto;
  border-radius: 8px;
`;

export const ImagemapPopOverFieldsContent = styled.div`
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 8px;
  margin-bottom: 16px;
  .imagemap-icon {
    cursor: pointer;
    #cells-stroke-layer {
      transition: stroke 0.25s ease-in-out 0s;
    }
    #cells-fill-layer,
    #cells-text-layer {
      transition: fill 0.25s ease-in-out 0s;
    }
    &.is-active {
      #cells-stroke-layer {
        stroke: ${theme.colors.blue006};
      }
      #cells-fill-layer,
      #cells-text-layer {
        fill: ${theme.colors.blue006};
      }
    }
    &.is-error {
      #cells-stroke-layer {
        stroke: ${theme.colors.red006};
      }
      #cells-fill-layer,
      #cells-text-layer {
        fill: ${theme.colors.red006};
      }
    }
    &:hover {
      #cells-stroke-layer {
        stroke: ${theme.colors.blue006};
      }
      #cells-fill-layer,
      #cells-text-layer {
        fill: ${theme.colors.blue006};
      }
    }
  }
`;

interface ImagemapCustomIconProps {
  isSmall?: boolean;
}

export const ImagemapCustomIcon = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['isSmall']),
})<ImagemapCustomIconProps>`
  display: inline-block;
  width: 55px;
  height: 55px;
  box-sizing: border-box;
  vertical-align: top;
  border: 2px solid ${theme.colors.neutral004};
  color: ${theme.colors.neutral004};
  font-size: 13px;
  cursor: pointer;
  transition:
    border 0.25s ease-in-out 0s,
    color 0.25s ease-in-out 0s;
  text-align: center;

  ${({ isSmall }) =>
    isSmall
      ? css`
          width: 61px;
          height: 24px;
          line-height: 20px;
        `
      : null}

  &.is-active,
  &:hover {
    border: 2px solid ${theme.colors.blue006};
    color: ${theme.colors.blue006};
  }
`;

export const ImagemapCustomBlocks = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  vertical-align: top;
  border: 2px solid ${theme.colors.neutral004};
  color: ${theme.colors.neutral004};
  font-size: 13px;
  margin: 0 12px 12px 0;
  cursor: pointer;
  transition:
    border 0.25s ease-in-out 0s,
    color 0.25s ease-in-out 0s;
  &.is-active,
  &:hover {
    border: 2px solid ${theme.colors.blue006};
    color: ${theme.colors.blue006};
  }
  &.is-error {
    border-color: ${theme.colors.red006};
    color: ${theme.colors.red006};
  }
  line-height: 30px;
  text-align: center;
`;

export const CarouselScrollContainer = styled.div`
  position: relative;
  margin-left: 40px;
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  > div {
    scroll-snap-align: start;
  }

  ${hideScrollBar};
`;

export const CarouselScrollPreviewContainer = styled.div`
  display: flex;
  position: relative;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 12px;
  padding-right: 12px;
  > div {
    min-width: 223px;
  }
`;

interface CarouselAddZoneProps {
  height?: number;
}

export const CarouselAddZone = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['height']),
})<CarouselAddZoneProps>`
  border: 1px dashed ${theme.colors.neutral005};
  border-right: none;
  background-color: ${theme.colors.white001};
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  min-width: 83px;
  display: flex;
  font-size: 50px;
  font-weight: 300;
  color: ${theme.colors.neutral005};
  transition: all 0.2s ease-in;
  height: ${({ height }) => (height === undefined ? 'auto' : `${height}px`)};
  cursor: pointer;
  :hover {
    border-color: ${theme.colors.blue006};
    color: ${theme.colors.blue006};
  }

  ${flexCenter};
`;

interface CarouselThumbnailProps {
  active?: boolean;
}

export const CarouselThumbnail = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['active']),
})<CarouselThumbnailProps>`
  width: 32px;
  height: 36px;
  border-radius: 4px;
  background-color: ${theme.colors.white001};
  margin-left: 5px;
  margin-right: 5px;
  border: ${({ active }) => (active ? `solid 2px ${theme.colors.blue006}` : 'none')};
  cursor: pointer;
  display: flex;
  ${flexCenter};
`;

export const CarouselThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-horizontal-drag {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  :hover {
    .icon-horizontal-drag {
      opacity: 1;
    }
  }
`;

interface PopoversContainerProps {
  isOverlay?: boolean;
}

export const PopoversContainer = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['isOverlay']),
})<PopoversContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  ${({ isOverlay }) =>
    isOverlay
      ? css`
          opacity: 0;
          transition: 0.45s opacity ease-in-out;
          background-color: rgba(44, 56, 72, 0.8);

          &:hover {
            opacity: 1;
          }
        `
      : css`
          background-color: ${theme.colors.white001};
        `};
`;

export interface PopoverIconContainerProps {
  onLight?: boolean;
}

export const ParamPopoverIconContainer = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['onLight']),
})<PopoverIconContainerProps>`
  position: relative;
  display: inline-flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ onLight }) => (onLight ? theme.colors.neutral006 : theme.colors.white001)};
  &:hover i,
  &:hover > p {
    color: ${({ onLight }) => (onLight ? theme.colors.blue006 : theme.colors.blue004)};
  }
  i {
    color: ${({ onLight }) => (onLight ? theme.colors.neutral007 : theme.colors.white001)};
  }
  & > p {
    margin-top: 14px;
    font-size: 14px;
  }
`;

export const PopoverContentContainer = styled.div`
  width: 350px;
  max-height: 450px;
  overflow: auto;
`;

export const LineMessageBubble = styled.div`
  box-sizing: border-box;
  display: inline-block;
  padding: 5px 10px;
  width: auto;
  min-width: 30px;
  max-width: calc(100% - 64px);
  height: auto;
  min-height: 30px;
  line-height: 1.5;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  background-color: ${theme.colors.white001};
  border-radius: 16px;
`;
