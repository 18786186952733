import { toNumber } from '@chatbotgang/etude/pitch-shifter/number';
import { includes } from 'es-toolkit/compat';

import type { CdpDataSource, CdpTagUniqueId } from 'shared/models/cdp';

import { CdpDataSourceEnum } from 'shared/models/cdp';

export function isCdpDataSourceInternal(source: string): source is CdpDataSource {
  return source === CdpDataSourceEnum.Maac || source === CdpDataSourceEnum.Caac;
}

export function isCdpTagUniqueId(value: unknown): value is CdpTagUniqueId {
  if (typeof value !== 'string') return false;

  const [source, id] = value.split('-');

  const transformedId = toNumber(id);

  if (isNaN(transformedId) || transformedId <= 0) return false;

  return includes(Object.values(CdpDataSourceEnum), source);
}

// A simple utility for generating a unique ID for a CDP tag, mainly used for consistency across the app
export function getCdpTagUniqueId({
  id,
  source,
}: {
  id: number;
  source: CdpDataSource;
}): CdpTagUniqueId {
  return `${source}-${id}`;
}

// Same as above except in reverse
export function getCdpTagIdSource({ uniqueId }: { uniqueId: `${CdpDataSource}-${number}` }): {
  id: number;
  source: CdpDataSource;
} {
  const [source, id] = uniqueId.split('-') as [CdpDataSource, string];

  return {
    id: Number(id),
    source,
  };
}
