import { noop } from 'es-toolkit';
import { useTranslation } from 'react-i18next';

import type { AcceptedFlexMessage } from './types';
import type { Action, FlexBubble } from '@line/bot-sdk';

import { isUrl } from 'lib/validator/rules/url';
import {
  isCustomParameterWithLink,
  isPrizeModule,
} from 'shared/components/Editor/LineMessageEditor/utils/helper';
import { transformKey } from 'shared/components/Editor/utils/helper';

import { useProps } from './context';
import { isMessageAction, isURIAction } from './types';
import { replacePrizeRedeemWithKey } from './utils';

type FlexAction =
  | Action<{
      label: string;
    }>
  | undefined;

export const useAction = (action: FlexAction): [string, () => void] => {
  const { t } = useTranslation();
  const {
    message: { parameters },
    onAddUserMessageText,
  } = useProps();
  let text = '';
  let actionType = '';
  let clickCallback;
  if (isMessageAction(action)) {
    text = action.text;
    actionType = t('message.send');
    clickCallback = () => {
      if (text) onAddUserMessageText(text);
    };
  } else if (isURIAction(action)) {
    actionType = t('common.openUrl');
    if (isUrl(action.uri)) {
      text = action.uri;
    } else {
      const key = transformKey(action.uri);
      const parameter = parameters.find((d) => d.key === key);
      if (key && parameter && isCustomParameterWithLink(parameter.data)) {
        text = parameter.data.url;
      }
    }
    clickCallback = () => {
      if (isUrl(text)) {
        window.open(text);
      }
    };
  }

  return [`${actionType}: ${text}`, clickCallback ?? noop];
};

type ReplaceType = 'prize';

export const useReplacedFlexContent = (
  message: AcceptedFlexMessage,
  replaceTypes?: Array<ReplaceType>,
): Array<FlexBubble> => {
  const contents = message.data.contents.type === 'bubble' ? [message.data.contents] : [];

  return contents.map((content) => {
    if (replaceTypes?.includes('prize') && isPrizeModule(message)) {
      return replacePrizeRedeemWithKey(content, message);
    }
    return content;
  });
};
