import type { Action } from '@line/bot-sdk';
import type { EditorDataType } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { ISOString, PageResponse } from 'components/Types';
import type { Dayjs } from 'lib/dayjs';
import type { BarcodeType, PrizeCodeStatus } from 'modules/Prize/utils';
import type { MemberListItem } from 'shared/models/members';
import type { TagMetadataItemWithOrgId } from 'shared/models/tag/metadata';

interface Utm {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
}

export type PrizeStatus = 'activated' | 'expired';

export const PrizeRedemptionModeEnum = {
  FixedCode: 'fixed_code',
  ChangedCode: 'changed_code',
  NoCode: 'no_code',
  UniqueCode: 'unique_code',
  UniqueUrl: 'unique_url',
  UniqueUrlWithPassword: 'unique_url_with_password',
} as const;

export type PrizeRedemptionMode =
  (typeof PrizeRedemptionModeEnum)[keyof typeof PrizeRedemptionModeEnum];

export type ValidatablePrizeRedemptionMode = Exclude<PrizeRedemptionMode, 'fixed_code' | 'no_code'>;

type PrizeReport = {
  total_count: number | null;
  received_count: number | null;
  used_count: number | null;
  revenue: number | null;
};

export const PrizeCategoryEnum = {
  OnlineCoupon: 'online_coupon',
  OfflineCoupon: 'offline_coupon',
  LineCoupon: 'line_coupon',
  CustomCoupon: 'custom_coupon',
  GameCoupon: 'game_coupon',
  IntegrationCoupon: 'integration_coupon',
  PointsCoupon: 'points_coupon',
} as const;

export type PrizeCategory = (typeof PrizeCategoryEnum)[keyof typeof PrizeCategoryEnum];

export type Prize = {
  id: number;
  name: string;
  category: PrizeCategory;
  purchase_started_at: ISOString | null;
  purchase_ended_at: ISOString | null;
  validity_ended_at: ISOString;
  status: PrizeStatus;
  report: PrizeReport;
};

export type PrizeList = Omit<PageResponse<Prize>, 'count'>;

export type MyCouponsMetrics = {
  purchase_started_at: ISOString;
  purchase_ended_at: ISOString;
  link: string;
};

type PrizeAction = Action<{
  type: Extract<Action['type'], 'message' | 'uri'>;
  label: string;
  text?: string; // Required with message
  uri?: string; // Required with uri
}> &
  Partial<Utm> & { open_external_browser?: boolean };

export type PrizeDetailOnlineCoupon = Omit<Prize, 'status'> & {
  category: Extract<PrizeCategory, 'online_coupon'>;
  code_mode: Extract<
    PrizeRedemptionMode,
    'changed_code' | 'fixed_code' | 'unique_url' | 'unique_url_with_password'
  >;
  fixed_code: null | string;
  image_url: string;
  description: string;
  messages: null;
  parameter:
    | {
        // TODO: remove this deprecated object in 2024. Preserved for backward compatibility.
        button_name: string;
        button_url: string;
        open_external_browser: boolean;
        utm_source: string;
        utm_medium: string;
        utm_campaign: string;
        utm_content: string;
      }
    | {
        settings: {
          action: PrizeAction;
        };
      };
  tags: Array<TagMetadataItemWithOrgId>;
  redeem_tags: [];
  file_id?: string;
};

export type PrizeDetailOfflineCoupon = Omit<Prize, 'status'> & {
  category: Extract<PrizeCategory, 'offline_coupon'>;
  code_mode: PrizeRedemptionMode;
  fixed_code: null | string;
  image_url: string;
  description: string;
  messages: null;
  parameter: {
    settings: {
      show_barcode: boolean;
      show_redeem_button: boolean;
      barcode_type?: BarcodeType;
    };
  };
  tags: Array<TagMetadataItemWithOrgId>;
  redeem_tags: Array<TagMetadataItemWithOrgId>;
  file_id?: string;
};

export type PrizeDetailLineCoupon = Omit<Prize, 'status'> & {
  category: Extract<PrizeCategory, 'line_coupon'>;
  code_mode: Extract<PrizeRedemptionMode, 'changed_code'>;
  fixed_code: null;
  image_url: string;
  description: string;
  messages: null;
  parameter: Record<string, never>;
  tags: Array<TagMetadataItemWithOrgId>;
  redeem_tags: [];
  file_id?: string;
};

export type PrizeDetailGameCoupon = Omit<Prize, 'status'> & {
  category: Extract<PrizeCategory, 'game_coupon'>;
  code_mode: Extract<PrizeRedemptionMode, 'no_code'>;
  fixed_code: null;
  image_url: string;
  description: string;
  messages: null;
  parameter:
    | {
        // TODO: remove this deprecated object in 2024. Preserved for backward compatibility.
        activity_id: number;
        game_id: string;
        increase_quota: number;
      }
    | {
        settings: {
          activity_id: number;
          game_id: string;
          increase_quota: number;
        };
      };
  tags: Array<TagMetadataItemWithOrgId>;
  redeem_tags: [];
  file_id?: string;
};

export type PrizeDetailBrandCoupon = Omit<Prize, 'status'> & {
  category: Extract<PrizeCategory, 'custom_coupon'>;
  code_mode: Extract<PrizeRedemptionMode, 'no_code'>;
  fixed_code: null;
  image_url: string;
  description: string;
  messages: Array<EditorDataType>;
  parameter: Record<string, never>;
  tags: Array<TagMetadataItemWithOrgId>;
  redeem_tags: [];
  file_id?: string;
};

export type PrizeDetailIntegrationCoupon = Omit<Prize, 'status'> & {
  category: Extract<PrizeCategory, 'integration_coupon'>;
  code_mode: Extract<PrizeRedemptionMode, 'no_code'>;
  fixed_code: null;
  image_url: string;
  description: string;
  messages: null;
  parameter:
    | {
        // TODO: remove this deprecated object in 2024. Preserved for backward compatibility.
        note: string;
        point_quantity: number;
        point_expired_datetime: ISOString;
        action: {
          type: 'message';
          label: string;
          text: string;
        };
      }
    | {
        settings: {
          note: string;
          point_quantity: number;
          point_expired_datetime: ISOString;
          action: {
            type: 'message';
            label: string;
            text: string;
          };
        };
      };
  tags: Array<TagMetadataItemWithOrgId>;
  redeem_tags: [];
  file_id?: string;
};

export type PrizeDetailPointsCoupon = Omit<Prize, 'status'> & {
  category: Extract<PrizeCategory, 'points_coupon'>;
  code_mode: Extract<PrizeRedemptionMode, 'no_code'>;
  fixed_code: null;
  image_url: string;
  description: string;
  messages: null;
  parameter: {
    settings: {
      points_memo: string;
      points_count: number;
      points_expire_date: ISOString;
      points_category: string;
      action: PrizeAction;
    };
    binding: {
      message: string;
      action: PrizeAction;
    } | null;
  };
  tags: Array<TagMetadataItemWithOrgId>;
  redeem_tags: [];
  file_id?: string;
};

export type PrizeDetail =
  | PrizeDetailOnlineCoupon
  | PrizeDetailOfflineCoupon
  | PrizeDetailLineCoupon
  | PrizeDetailGameCoupon
  | PrizeDetailBrandCoupon
  | PrizeDetailIntegrationCoupon
  | PrizeDetailPointsCoupon;

export type CreatePrizePayload = Omit<PrizeDetail, 'id' | 'report' | 'tags' | 'redeem_tags'> & {
  bot_id: number;
  tags: Array<number>;
  redeem_tags: Array<number>;
};

interface PrizeActionFormValues {
  actionType?: PrizeAction['type'];
  actionLabel?: string;
  actionUri?: string;
  actionText?: string;
  openExternalBrowser?: boolean;
  trackLink?: boolean;
  utm?: {
    source?: string;
    medium?: string;
    campaign?: string;
    content?: string;
  };
}

export interface PrizeFormValues {
  prizeName: string;
  validityEndDate: Dayjs;
  prizeCategory: PrizeCategory;
  redemptionDetail: {
    redemptionMode?: PrizeDetail['code_mode'];
    fileId?: PrizeDetail['file_id'];
    uploadedCodeCount?: number | null;
    fixedCode?: PrizeDetail['fixed_code'];
    showBarcode?: boolean;
    showRedeemButton?: boolean;
    barcodeType?: BarcodeType;
  };
  messages?: NonNullable<PrizeDetail['messages']>;
  gameCoupon?: {
    id: number | undefined;
    gameId: string;
    times: number;
  };
  integrationCoupon?: {
    reason: string;
    point: number;
    validity: Dayjs;
  };
  pointsCoupon?: {
    memo: string; // Reason
    count: number; // Point
    expireDateActive: boolean;
    expireDate: Dayjs; // Validity
    category: string;
  };
  tags: Array<number>;
  redeemTags: Array<number>;
  action?: PrizeActionFormValues;
  bindingActive?: boolean;
  bindingMessage?: string;
  bindingAction?: PrizeActionFormValues;
  imageUrl?: string | null;
  description?: string;
}

export type PrizePurchasedState = {
  category: PrizeCategory;
  status: 'activated' | 'unactivated';
  purchase_started_at: ISOString | null;
  purchase_ended_at: ISOString | null;
};

export type PrizeRecord = {
  id: number;
  member_avatar: MemberListItem['avatar'];
  member_name: MemberListItem['name'];
  code: string;
  status: `${PrizeCodeStatus}`;
  unique_url?: string;
  unique_password?: string;
};

export type PrizeRecords = PageResponse<PrizeRecord>;

export type DeleteCodesResponse = {
  total: number;
  success: number;
  fail: number;
};

export type BarcodeKeyType = keyof typeof BarcodeType;

export type BarcodeOptionListType = Array<{
  name: Exclude<BarcodeKeyType, 'Code 39-Checksum'> | React.JSX.Element;
  value: BarcodeType;
}>;
