/**
 * @description
 * Preload an asset.
 *
 * If you are going to preload an image, please use preloadImage() instead.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types/preload
 * @param attributes Attributes for `<link />`.
 * @returns Promise<void>. Throw an error if the asset is not preloaded.
 * @example
 * ```ts
 * preload({ href: img, as: 'image' }).catch(noop);;
 * ```
 */
function preload(attributes: Record<string, string>): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const link = document.createElement('link');
    Object.entries(attributes).forEach(([key, value]) => {
      link.setAttribute(key, value);
    });
    link.setAttribute('rel', 'preload');
    link.onload = function onload() {
      link.remove();
      resolve();
    };
    link.onerror = function onerror() {
      link.remove();
      reject();
    };
    document.head.append(link);
  });
}

/**
 * @description
 * Preload an image.
 * @param src The source of the image.
 * @returns Promise<void>. Throw an error if the image is not loaded.
 * @example
 * ```ts
 * preloadImage(pathToImage).catch(noop);
 * ```
 */
export function preloadImage(src: string): Promise<void> {
  return preload({ href: src, as: 'image' }).then(() => {
    return new Promise<void>((resolve, reject) => {
      const img = new Image();
      img.onload = function onload() {
        img.remove();
        resolve();
      };
      img.onerror = function onerror() {
        img.remove();
        reject();
      };
      img.src = src;
    });
  });
}
