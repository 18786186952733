import { createVarCall } from '@chatbotgang/motif';
import styled from '@emotion/styled';

import type { FC } from 'react';

import { theme } from 'theme';

const LineMessageEditorPreviewContainer = styled.div`
  position: relative;
  width: ${theme.sizes.linePreviewWidth};
  height: ${theme.sizes.linePreviewHeight};
  border: 6px solid ${theme.colors.neutral003};
  border-radius: 50px;
  box-sizing: border-box;
  background-color: ${theme.colors.white001};
  padding: 52px 20px;
  &::before {
    content: '';
    width: 71px;
    height: 6px;
    background-color: ${theme.colors.neutral002};
    position: absolute;
    top: 28px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 6.5px;
  }
`;

const LineMessageEditorFadedPreviewContainer = styled(LineMessageEditorPreviewContainer)`
  mask-image: linear-gradient(rgb(0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);
  height: ${theme.sizes.linePreviewFadedHeight};
  padding-bottom: 0;
  border-radius: 50px 50px 0 0;
`;

const LineMessageEditorPreviewContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  word-break: normal;
  background-color: ${createVarCall('--static-bg-plain')};
  overflow: auto;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const LineMessageEditorPreviewWrapper: FC<{
  contentRef?: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}> = function LineMessageEditorPreviewWrapper({ contentRef, children, ...props }) {
  return (
    <LineMessageEditorPreviewContainer {...props}>
      <LineMessageEditorPreviewContent ref={contentRef}>{children}</LineMessageEditorPreviewContent>
    </LineMessageEditorPreviewContainer>
  );
};

export const LineMessageEditorFadedPreviewWrapper: FC<{
  children: React.ReactNode;
}> = function LineMessageEditorPreviewWrapper({ children, ...props }) {
  return (
    <LineMessageEditorFadedPreviewContainer
      style={{
        borderBottom: 0,
      }}
      {...props}
    >
      <LineMessageEditorPreviewContent>{children}</LineMessageEditorPreviewContent>
    </LineMessageEditorFadedPreviewContainer>
  );
};
