import { z } from 'zod';

import type { FlexBox } from '@line/bot-sdk';

/**
 * This file contains Zod schemas for types imported from the LINE SDK
 * We only need a subset of types for parsing message data but this is better than using `z.custom()`
 */
export const FlexBoxMarginSchema = z.enum(['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']);

export const FlexBoxSpacingSchema = z.enum(['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']);

export const FlexBoxJustifyContentSchema = z.enum([
  'flex-start',
  'center',
  'flex-end',
  'space-between',
  'space-around',
  'space-evenly',
]);

/**
 * Note: FlexBox is a recursive type due to the contents prop, which is type `FlexComponent`
 * When using this with Zod it can cause type errors in React Hook Form
 * Since we aren't too concerned about validating this we are using `z.custom` as a type assertion
 * This seems to reduce overall memory usage and no errors are emitted
 */
export const FlexBoxCustomSchema = z.custom<FlexBox>();

// Note: we only use a subset of the LINE SDK schema
export const FlexImageSchema = z.object({
  backgroundColor: z.string().optional(),
  type: z.literal('image'),
  url: z.string(),
  margin: FlexBoxMarginSchema.optional(),
  animated: z.boolean().optional(),
  aspectRatio: z.string().optional(),
  aspectMode: z.enum(['cover', 'fit']).optional(),
  /** Note: LINE SDK supports many different sizes but we only use `full` */
  size: z.literal('full').optional(),
  position: z.enum(['relative', 'absolute']).optional(),
  offsetTop: z.string().optional(),
});

export const FlexTextMarginSchema = z.enum(['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']);

export const FlexTextSizeSchema = z.string();

export const FlexTextWeightSchema = z.enum(['regular', 'bold']);
